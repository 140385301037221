$bp-xs: 380px;
$bp-s: 600px;
$bp-m: 900px;
$bp-l: 1200px;
$bp-xl: 1450px;

:export {
  bp-xs: $bp-xs;
  bp-s: $bp-s;
  bp-m: $bp-m;
  bp-l: $bp-l;
  bp-xl: $bp-xl;
}