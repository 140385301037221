@import '@/app/variables';

.title {
  margin-bottom: 50px;
  text-align: left;
  @media(max-width: $bp-m) {
    margin-bottom: 16px;
  }
}

.wrapper {
  font-family: 'Gilroy', sans-serif;
  background-color: var(--text-color-inverted);
  border-radius: 10px;
  box-shadow: 5px 5px 35px 0 hsla(213, 21%, 14%, 0.05);
  margin-bottom: 200px;
  padding: 0 30px;

  & > .content:last-child {
    padding-bottom: 20px;
  }

  @media(max-width: $bp-m) {
    margin-bottom: 80px;
    padding: 0 16px;
  }
}

.header {
  position: relative;
  padding: 20px 25px 20px 0;
  font-weight: 500;
  font-size: var(--fz-20);
  line-height: 34px;


  &:not(:last-of-type) {
    border-bottom: 1px solid hsla(0, 0%, 89%, 1);
  }
}

.header.active {
  border-bottom: none;
}

.svg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  width: 20px;
  height: 20px;
  pointer-events: none;
  transform-origin: center center;
  transition: transform .5s;
}

.svg.active {
  transform: translateY(-50%) rotate(45deg);
}

.content {
  display: none;
  color: var(--pale-text-color);
  font-size: var(--fz-16);
  line-height: 27px;
  white-space: pre-wrap;

  @media(max-width: $bp-m) {
    font-size: var(--fz-14);
    line-height: 24px;
  }
}

.content.active {
  display: block;
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;

  @media(max-width: $bp-m) {
    font-size: var(--fz-18);
    line-height: 30px;
  }
}