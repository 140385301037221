@import '@/app/variables.scss';

html {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button {
  outline: none;
  border: none;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  background-color: transparent;
  transition: 0.3s;
  cursor: pointer;
  font-family: Gilroy, sans-serif;

  &:hover {
    opacity: 0.8;
  }
}

input {
  transition: 0.3s;
  font-family: Gilroy, sans-serif;

  &:focus {
    outline: 2px solid var(--text-color-inverted);
  }

  &:hover {
    opacity: 0.8;
  }
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Semibold'),
  url('../../public/fonts/Gilroy-Heavy.woff2') format('woff2'),
  url('../../public/fonts/Gilroy-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Semibold'),
  url('../../public/fonts/Gilroy-Bold.woff2') format('woff2'),
  url('../../public/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Semibold'),
  url('../../public/fonts/Gilroy-Semibold.woff2') format('woff2'),
  url('../../public/fonts/Gilroy-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Regular'),
  url('../../public/fonts/Gilroy-Regular.woff2') format('woff2'),
  url('../../public/fonts/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Medium'),
  url('../../public/fonts/Gilroy-Medium.woff2') format('woff2'),
  url('../../public/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  --primary-customer-color: hsla(213, 100%, 46%, 1);
  --light-customer-color: hsla(214, 87%, 88%, 1);
  --primary-customer-light-color: hsla(213, 92%, 68%, 1);
  --primary-customer-superlight-color: hsla(215, 94%, 93%, 1);
  --primary-executor-superlight-color: hsla(18, 33%, 94%, 1);
  --primary-partner-superlight-color: hsla(252, 100%, 94%, 1);
  --primary-executor-color: hsla(29, 89%, 57%, 1);
  --light-executor-color: hsla(29, 77%, 84%, 1);
  --primary-executor-light-color: hsla(29, 100%, 72%, 1);
  --primary-partner-color: hsla(263, 100%, 54%, 1);
  --primary-partner-light-color: hsla(263, 100%, 66%, 1);
  --text-color: hsla(212, 25%, 26%, 1);
  --light-text-color: hsla(0, 0%, 54%, 1);
  --placeholder-color: hsla(216, 13%, 69%, 1);
  --text-color-inverted: hsla(0, 0%, 100%, 1);
  --pale-text-color: hsla(0, 0%, 54%, 1);
  --section-color: hsla(222, 100%, 98%, 1);
  --light-green: hsla(136, 60%, 95%, 1);
  --green: hsla(135, 59%, 49%, 1);
  --gray70: hsla(210, 2%, 67%, 1);
  --gray80: hsla(0, 0%, 94%, 1);
  --gray90: hsla(0, 0%, 96%, 1);


  --fz-12: 12px;
  --fz-14: 14px;
  --fz-16: 16px;
  --fz-18: 18px;
  --fz-20: 20px;
  --fz-24: 24px;
  --fz-32: 32px;
  --fz-36: 36px;
  --fz-40: 40px;
  --fz-42: 42px;
  --fz-48: 48px;
  --fz-52: 52px;
  --fz-62: 62px;
  --fz-80: 80px;
  --fz-120: 120px;

  color: var(--text-color);
  background: var(--section-color);
  font-size: 14px;
  font-weight: 400;
  font-family: Gilroy, sans-serif;

  &.hidden {
    overflow: hidden;
  }
}

.container {
  max-width: 1570px;
  margin: 0 auto;
  padding: 0 30px;
  transform-origin: center center;
  transform: scale(0.9); //🩼😢🩼
  @media (max-width: $bp-m) {
    padding: 0 20px;
    transform: none;
  }
}

a {
  text-decoration: none;
  transition: 0.3s;
  font-family: Gilroy, sans-serif;

  &:hover {
    opacity: 0.8;
  }
}

.buttonLink {
  display: block;
  font-size: var(--fz-18);
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  border-radius: 7px;
  padding: 15px;
}

.b24-form-wrapper.b24-form-shadow {
  box-shadow: none !important;
}







