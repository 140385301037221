@import '@/app/variables';

.wrapper {
  width: 100%;
  font-family: 'Gilroy', sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 60px;
  @media(max-width: $bp-m) {
    flex-direction: column;
    justify-content: center;
    padding-top: 32px;
  }
}

.logo {
  margin-right: auto;
  display: block;
  @media(max-width: $bp-m) {
    margin-right: 0;
  }
}

.link {
  display: block;
  border-radius: 7px;
  color: var(--text-color-inverted);
  width: 250px;
  height: 61px;
  line-height: 61px;
  text-align: center;
  font-weight: 500;
  font-size: var(--fz-18);

  @media(max-width: $bp-m) {
    height: 55px;
    line-height: 55px;
  }
}

.link.customer {
  background-color: var(--primary-customer-color);

  @media(max-width: $bp-m) {
    order: 3;
  }
}

.link.executor {
  background-color: var(--primary-executor-color);
}