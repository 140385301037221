@import '@/app/variables';

.navigation {
  margin-top: 24px;
  @media(max-width: $bp-m) {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.list {
  list-style-type: none;
  display: flex;
  column-gap: 30px;
  font-family: Gilroy, sans-serif;
  @media(max-width: $bp-m) {
    display: block;
    text-align: center;
  }

  & > li {
    font-family: inherit;
    font-size: var(--fz-18);
    @media(max-width: $bp-m) {
      margin-bottom: 16px;
    }
  }
}

.link {
  color: var(--text-color-inverted);
  display: block;
  position: relative;

  @media(max-width: $bp-m) {
    font-size: var(--fz-16);
    line-height: 27px;
  }

  &:hover {
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: var(--text-color-inverted);
    height: 2px;
    width: 0;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    transition: .5s;
  }

  &:hover:after {
    width: 50%;
  }
}

.link.active {
  &:after {
    width: 100%;
  }
}