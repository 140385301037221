@import "src/app/variables.scss";

.container {
  padding: 16px 20px 0 20px;

  @media (min-width: $bp-l) {
    padding: 200px 16px;
  }
}

.firstBlockWrapper {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  gap: 16px;
  height: auto;


  @media (min-width: $bp-m) {
    flex-direction: row;
    margin-top: 60px;
    height: 360px;
    gap: 30px;
    margin-bottom: 30px;
  }
}

.desktop {
  display: none;
  @media (min-width: 356px) {
    display: block;
  }
}

.mobile {
  display: block;
  @media (min-width: 356px) {
    display: none;
  }
}

.blockWrapper {
  display: flex;
  margin-bottom: 16px;
  gap: 0;
  flex-direction: column;

  @media (min-width: $bp-m) {
    flex-direction: row;
    margin-bottom: 30px;
    gap: 30px;
  }
}

.infoCard {
  max-width: 100%;
  border-radius: 10px;
  position: relative;
  flex: 2;
  min-height: 272px;
  overflow: hidden;


  @media (min-width: $bp-m) {
    min-height: 360px;
    max-width: 1010px;
  }

  img {
    position: absolute;
    top: -30px;
    left: -30px;
    right: 0;
    bottom: 0;
    display: block;
    min-height: 100%;
    object-fit: cover;
  }

  p {
    font-weight: 700;
    color: var(--text-color-inverted);
    font-size: var(--fz-14);
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: fit-content;
    line-height: 24px;


    @media (min-width: $bp-m) {
      font-size: var(--fz-20);
      line-height: 34px;
      bottom: 20px;
      left: 20px;
    }
  }
}

.mainCard {
  position: relative;
  border-radius: 10px;
  background: var(--text-color-inverted);
  min-height: 160px;
  width: 100%;
  padding: 16px;
  min-width: 100% !important;
  margin-bottom: 16px;

  &:nth-child(3) {
    margin-bottom: 0;
  }

  @media (min-width: $bp-m) {
    min-width: auto !important;
    padding: 40px;
    min-height: 360px;
    margin-top: 0;
    flex: 1;
  }

  svg {
    width: 60px;
    height: 60px;

    @media (min-width: $bp-m) {
      width: 100px;
      height: 100px;
    }
  }

  p {
    color: var(----text-color);
    font-size: var(--fz-18);
    font-weight: 400;
    position: absolute;
    left: 16px;
    bottom: 16px;
    line-height: 29px;


    @media (min-width: $bp-m) {
      left: 40px;
      bottom: 40px;
      font-size: var(--fz-24);
    }
  }
}