@import '@/app/variables.scss';

.page-title {
  font-size: var(--fz-120);
  font-weight: 500;
  line-height: 108px;
  text-align: left;
  font-family: Gilroy, sans-serif;
  @media(max-width: $bp-l) {
    font-size: var(--fz-80);
  }
  @media (max-width: $bp-m) {
    font-size: var(--fz-48);
    line-height: 50px;
  }

}

.section-title {
  font-size: var(--fz-62);
  font-weight: 700;
  line-height: 75px;
  font-family: Gilroy, sans-serif;
  @media (max-width: $bp-m) {
    font-size: var(--fz-36);
    line-height: 45px;
  }
}

.subsection-title {
  font-size: var(--fz-40);
  font-weight: 700;
  line-height: 48px;
  font-family: Gilroy, sans-serif;
}
