@import '@/app/variables';

.wrapper {
  max-width: 1860px;
  padding: 0 20px;
  margin: 200px auto 50px;

  @media(max-width: $bp-m) {
    margin: 80px auto 20px;
  }
}

.inner {
  border-radius: 20px;
  background-color: hsla(216, 11%, 18%, 1);
  padding-bottom: 60px;

  @media(max-width: $bp-m) {
    padding-bottom: 32px;
  }
}

.end {
  display: flex;
  justify-content: flex-end;
  @media(max-width: $bp-m) {
    justify-content: center;
  }
}

.start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media(max-width: $bp-m) {
    justify-content: center;
  }
}

.pay-info {
  display: flex;
  column-gap: 30px;
  color: var(--gray70);
  font-weight: 400;
  font-size: var(--fz-14);
  @media(max-width: $bp-m) {
    justify-content: center;
    margin-top: 20px;
    font-size: var(--fz-12);
    line-height: 20px;
  }
}

.foot {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: var(--gray70);
  font-weight: 400;
  font-size: var(--fz-14);
  white-space: pre;
  line-height: 20px;


  @media(max-width: $bp-m) {
    font-size: var(--fz-12);
    line-height: 20px;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }

  & a {
    color: inherit;
  }
}

.policy {
  @media(max-width: $bp-m) {
    order: 3;
  }
}

.politics {
  display: flex;
  column-gap: 20px;
  margin-bottom: 10px;

  @media(max-width: $bp-m){
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}

.user-info{
  margin-top: -20px;
}
