@import '@/app/variables';

.wrapper {
  min-height: 100dvh;
  padding-top: 160px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
  z-index: 5;
  @media(max-width: $bp-m) {
    //display: flex;
    //flex-direction: column;
    padding-top: 157px;
    padding-bottom: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 614px;
    width: 1024px;
    right: 20px;
    bottom: 190px;
    background-image: url('../../images/Main/vehicle-desktop.webp');
    background-size: cover;
    background-repeat: no-repeat;
    transform: scale(-1.7, 1.7);
    z-index: 0;
    @media(max-width: $bp-m) {
      right: 50%;
      bottom: 40px;
      transform: scale(-1.3, 1.3) translateX(-25%);
      background-image: none;
    }
    @media(max-width: $bp-xs) {
      right: 50%;
      bottom: -100px;
      transform: scale(-0.8, 0.8) translateX(-50%);
    }
  }
}

.title {
  width: 100%;
  color: var(--text-color-inverted);
  white-space: pre-line;
  @media(max-width: $bp-m) {
    font-size: var(--fz-40);
  }
  @media(max-width: $bp-xs) {
    font-size: var(--fz-36);
  }
}

.header_form {
  @media(max-width: $bp-m) {
    display: none;
  }
}

