@import '@/app/variables';

.wrapper {
  font-family: 'Gilroy', sans-serif;
  background-color: var(--primary-partner-color);
  position: relative;
  padding: 60px;
  color: var(--text-color-inverted);
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 200px;
  @media(max-width: $bp-m) {
    padding: 16px;
    margin-bottom: 80px;
  }

  &:before {
    content: '';
    position: absolute;
    height: 701px;
    width: 701px;
    right: -200px;
    top: 0;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='513' height='480' viewBox='0 0 513 480' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='350.5' cy='299.5' r='350.5' fill='white' fill-opacity='0.1'/%3E%3Ccircle cx='401' cy='300' r='300' fill='white' fill-opacity='0.1'/%3E%3Ccircle cx='449.5' cy='299.5' r='251.5' fill='white' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
    @media(max-width: $bp-s) {
      background-image: url("data:image/svg+xml,%3Csvg width='275' height='203' viewBox='0 0 275 203' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='175.5' cy='175.5' r='175.5' fill='white' fill-opacity='0.1'/%3E%3Ccircle cx='201' cy='176' r='150' fill='white' fill-opacity='0.1'/%3E%3Cellipse cx='225' cy='175.5' rx='126' ry='125.5' fill='white' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
      right: -450px;
      top: 150px;
    }
  }
}

.title {
  max-width: 840px;
  font-weight: 700;
  font-size: var(--fz-40);
  line-height: 50px;
  margin-bottom: 24px;
  @media(max-width: $bp-m) {
    font-size: var(--fz-24);
    line-height: 30px;
    margin-bottom: 16px;
  }
}

.description {
  max-width: 559px;
  font-size: var(--fz-20);
  line-height: 34px;
  margin-bottom: 60px;
  @media(max-width: $bp-m) {
    font-size: var(--fz-14);
    line-height: 24px;
    margin-bottom: 24px;
  }
}

.link {
  font-size: var(--fz-18);
  color: var(--primary-partner-color);
  background-color: var(--text-color-inverted);
  width: 360px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  border-radius: 7px;
  display: block;
  @media(max-width: $bp-m) {
    width: 100%;
  }
}