@import '@/app/variables';

.wrapper {
  display: flex;
  column-gap: 10px;
  @media(max-width: $bp-m) {
    display: block;
  }
}

.button {
  border-radius: 7px;
  height: 61px;
  max-width: 200px;
  width: 15vw;
  font-size: var(--fz-18);
  line-height: 61px;
  text-align: center;
  @media(max-width: $bp-m) {
    width: 100%;
    max-width: 100%;
    display: block;
  }
}

.login {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--text-color-inverted);
  border: 1px solid var(--text-color-inverted);
}

.registration {
  background-color: var(--text-color-inverted);
  color: var(--primary-customer-color); //TODO
  @media(max-width: $bp-m) {
    margin-bottom: 10px;
  }
}