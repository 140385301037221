@import '@/app/variables';

.wrapper {
  position: relative;
  font-family: 'Gilroy', sans-serif;
  color: var(--text-color-inverted);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 5px 35px 0 hsla(213, 21%, 14%, 0.05);
  padding: 60px;
  margin-bottom: 200px;
  @media(max-width: $bp-m) {
    padding: 16px;
    margin-bottom: 80px;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    height: 100%;
    width: 50%;
    background-size: cover;
    pointer-events: none;
  }

  &:before {
    background-image: url('./left.png');
    top: 0;
    left: 0;
    z-index: 1;

    @media(max-width: $bp-s) {
      background-image: url('./center.png');
      width: 100%;
    }
  }

  &:after {
    background-image: url('./right.png');
    top: 0;
    left: 50%;
    z-index: 1;

    @media(max-width: $bp-s) {
      display: none;
    }
  }
}

.title {
  text-align: center;
  margin-bottom: 20px;
  @media(max-width: $bp-m) {
    margin-bottom: 8px;
    font-size: var(--fz-32) !important;
    line-height: 39px !important;
  }
}

.description {
  text-align: center;
  font-size: var(--fz-24);
  line-height: 29px;
  margin-bottom: 30px;
  @media(max-width: $bp-m) {
    font-size: var(--fz-14);
    line-height: 24px;
    margin-bottom: 24px;
  }
}

.link {
  display: block;
  color: var(--text-color-inverted);
  background-color: var(--text-color-inverted);
  font-size: var(--fz-18);
  width: 360px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  border-radius: 7px;
  margin: 0 auto;
  font-weight: 500;
  @media(max-width: $bp-m) {
    width: 100%;
    height: 55px;
    line-height: 55px;
  }
}