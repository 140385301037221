@import '@/app/variables';

.wrapper {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 5px 35px 0 hsla(213, 21%, 14%, 0.05);
  background-color: white;
  display: flex;
  font-family: 'Gilroy', sans-serif;
  @media(max-width: $bp-m) {
    display: block;

  }
}

.content {
  width: 50%;
  padding: 40px;
  @media(max-width: $bp-m) {
    width: 100%;
    padding: 16px 16px 0 16px;
  }
}

.title {
  margin-bottom: 30px;
  line-height: 71px;
  font-size: var(--fz-42);
  @media(max-width: $bp-l) {
    margin-bottom: 20px;
    line-height: 30px;
    font-size: var(--fz-24);
  }

}

.description {
  margin-bottom: 60px;
  font-weight: 500;
  line-height: 34px;
  font-size: var(--fz-20);
  @media(max-width: $bp-l) {
    margin-bottom: 24px;
    line-height: 24px;
    font-size: var(--fz-14);
  }
}

.link {
  display: block;
  color: var(--text-color-inverted);
  font-weight: 500;
  font-size: var(--fz-18);
  border-radius: 7px;
  width: 490px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  @media(max-width: $bp-l) {
    width: 100%;
  }
}