@import '@/app/variables';

.wrapper {
  background: linear-gradient(259.75deg, var(--primary-customer-light-color) -13.49%, var(--primary-customer-color) 105.24%);
  padding: 230px 0 60px;
  margin-bottom: 200px;
  min-height: 100dvh;

  @media (max-width: $bp-m) {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 0;
    margin-bottom: 40px;
  }

  > div {
    width: 100%;
    @media (max-width: $bp-m) {
      margin-top: auto;
    }
  }
}


.title {
  max-width: 1100px;
  color: var(--text-color-inverted);

  @media(max-width: $bp-m) {
    font-size: var(--fz-40) !important;
    line-height: 34px !important;
  }
}