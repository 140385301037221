@import '@/app/variables';

.description {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  @media(max-width: $bp-m) {
    display: block;
  }
}

.list {
  list-style-type: none;
  color: var(--text-color-inverted);
  font-style: inherit;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media(max-width: $bp-m) {
    margin-bottom: 30px;
  }

  & > li {
    font-style: inherit;
    padding-right: 10px;

    line-height: 28px;
    font-weight: 400;
    font-size: var(--fz-24);
    @media(max-width: $bp-l) {
      font-size: var(--fz-18);
    }

    a {
      font-style: inherit;
      color: inherit;
    }
  }

  & > li:not(:first-child) {
    padding-left: 10px;
    border-left: 2px solid var(--text-color-inverted)
  }
}

.link {
  color: var(--text-color-inverted);
  font-size: var(--fz-18);
  line-height: 31px;
  padding: 15px 60px 15px 15px;
  position: relative;
  cursor: pointer;
  border-radius: 7px;
  margin-left: 30px;

  &:after {
    position: absolute;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 12.5H5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 17.5L19 12.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 7.5L19 12.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media(max-width: $bp-m) {
    margin-top: 220px;
    margin-left: 0;
  }
}


.linkCustomer {
  &:after {
    content: none;
  }

  padding-right: 15px;
  padding-left: 40px;

  &:before {
    position: absolute;
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xMi44MzIgNi4xOTI3MUg3LjgzMjAzVjEuMTkyNzFDNy44MzIwMyAwLjk3MTY5NSA3Ljc0NDIzIDAuNzU5NzMzIDcuNTg3OTUgMC42MDM0NTNDNy40MzE2NyAwLjQ0NzE3MyA3LjIxOTcxIDAuMzU5Mzc1IDYuOTk4NyAwLjM1OTM3NUM2Ljc3NzY5IDAuMzU5Mzc1IDYuNTY1NzIgMC40NDcxNzMgNi40MDk0NCAwLjYwMzQ1M0M2LjI1MzE2IDAuNzU5NzMzIDYuMTY1MzcgMC45NzE2OTUgNi4xNjUzNyAxLjE5MjcxVjYuMTkyNzFIMS4xNjUzNkMwLjk0NDM1MSA2LjE5MjcxIDAuNzMyMzg5IDYuMjgwNTEgMC41NzYxMDkgNi40MzY3OUMwLjQxOTgyOSA2LjU5MzA3IDAuMzMyMDMxIDYuODA1MDMgMC4zMzIwMzEgNy4wMjYwNEMwLjMzMjAzMSA3LjI0NzA2IDAuNDE5ODI5IDcuNDU5MDIgMC41NzYxMDkgNy42MTUzQzAuNzMyMzg5IDcuNzcxNTggMC45NDQzNTEgNy44NTkzOCAxLjE2NTM2IDcuODU5MzhINi4xNjUzN1YxMi44NTk0QzYuMTY1MzcgMTMuMDgwNCA2LjI1MzE2IDEzLjI5MjQgNi40MDk0NCAxMy40NDg2QzYuNTY1NzIgMTMuNjA0OSA2Ljc3NzY5IDEzLjY5MjcgNi45OTg3IDEzLjY5MjdDNy4yMTk3MSAxMy42OTI3IDcuNDMxNjcgMTMuNjA0OSA3LjU4Nzk1IDEzLjQ0ODZDNy43NDQyMyAxMy4yOTI0IDcuODMyMDMgMTMuMDgwNCA3LjgzMjAzIDEyLjg1OTRWNy44NTkzOEgxMi44MzJDMTMuMDUzIDcuODU5MzggMTMuMjY1IDcuNzcxNTggMTMuNDIxMyA3LjYxNTNDMTMuNTc3NiA3LjQ1OTAyIDEzLjY2NTQgNy4yNDcwNiAxMy42NjU0IDcuMDI2MDRDMTMuNjY1NCA2LjgwNTAzIDEzLjU3NzYgNi41OTMwNyAxMy40MjEzIDYuNDM2NzlDMTMuMjY1IDYuMjgwNTEgMTMuMDUzIDYuMTkyNzEgMTIuODMyIDYuMTkyNzFaIgogICAgICAgICAgZmlsbD0iI2ZmZmZmZiIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-position: center;
    width: 14px;
    height: 14px;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}