@import "src/app/variables.scss";

.container {
  padding: 80px 20px 16px 20px;
  background: var(--section-color);
  height: fit-content;

  @media (min-width: $bp-l) {
    padding: 0 16px 200px 16px;
  }
}

.slider {
  width: 100%;
  height: 550px;
  background: var(--text-color-inverted);
  border-radius: 10px;
  padding: 40px;

  @media (max-width: $bp-l) {
    height: 610px;
  }

  @media (max-width: $bp-s) {
    padding: 16px;
    height: 410px;
  }
}

.title {
  margin-bottom: 60px;

  @media(max-width: 1240px) {
    margin-bottom: 16px;
  }
}

.counter {
  font-size: var(--fz-62);
  font-weight: 700;
  line-height: 75px;
  width: fit-content;

  @media (max-width: $bp-l) {
    font-size: var(--fz-40);
  }
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: $bp-l) {
    flex-direction: column;
    gap: 8px;
  }

  img {
    width: 100%;
    max-width: 490px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    order: 2;

    @media(min-width: 1240px) {
      max-width: 610px;
      width: 100%;
    }


    @media (max-width: $bp-l) {
      order: 1;
      max-height: 350px;
      max-width: 100%;
    }

    @media (max-width: $bp-s) {
      order: 1;
      max-height: 160px;
      max-width: 100%;
    }
  }
}

.info {
  order: 1;
  @media (max-width: $bp-l) {
    order: 2;
  }
}

.mobileFlex {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.titleSlider {
  font-size: var(--fz-80);
  font-weight: 600;
  line-height: var(--fz-80);
  color: var(--text-color);
  margin-top: 75px;

  @media (max-width: $bp-l) {
    font-size: var(--fz-24);
    margin-top: 8px;
    line-height: 30px;
  }
}

.descriptionSlider {
  color: var(--text-color);
  font-weight: 500;
  line-height: 34px;
  font-size: var(--fz-20);
  margin-top: 17px;

  @media (max-width: $bp-l) {
    font-size: var(--fz-14);
    margin-top: 8px;
    line-height: 24px;
  }
}

.button__bar {
  margin-top: 60px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  width: 100%;

  li {
    list-style: none;
    cursor: pointer;
    padding: 0;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    span {
      margin-top: 15px;
      font-size: var(--fz-18);
      line-height: 22px;
      white-space: nowrap;
    }
  }

  li button {
    position: relative;
    border: none;
    cursor: pointer;
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 99px;
    margin-right: 5px;
    z-index: 1;
  }

  li button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: var(--text-color-inverted);
    border-radius: 999px;
  }
}


.customer {
  li.slick-active button {
    background: var(--primary-customer-color) !important;
  }

  li button {
    background: var(--light-customer-color);
  }

  li:first-child button {
    background: var(--primary-customer-color) !important;
  }

  li button:hover {
    background: var(--primary-customer-color) !important;
  }
}


.executor {
  li button {
    background: var(--light-executor-color);
  }

  li:first-child button {
    background: var(--primary-executor-color) !important;
  }

  li button:hover {
    background: var(--primary-executor-color) !important;
  }

  li.slick-active button {
    background-color: var(--primary-executor-color) !important;
  }
}


.customer_line {
  border: 1px dashed var(--light-customer-color);
  width: 100%;
  position: absolute;
  top: 15px;
  left: 15px;
}

.executor_line {
  border: 1px dashed var(--light-executor-color);
  width: 100%;
  position: absolute;
  top: 15px;
  left: 15px;
}
