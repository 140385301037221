@import "src/app/variables.scss";

.container {
  padding: 80px 20px 16px 20px;

  @media (min-width: $bp-l) {
    padding: 0 16px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;


  @media (min-width: $bp-l) {
    width: 100%;
  }
}

.titleBlock {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.preview {
  position: absolute;
  top: -40px;
  right: -40%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color-inverted);
  font-weight: 800;
  font-size: var(--fz-16);
  border-radius: 10px;
  transform: rotate(5deg);
  white-space: nowrap;
  height: 43px;
  min-width: fit-content;
  padding: 8px 30px;
  margin-left: 20px;

  @media (min-width: $bp-m) {
    position: static;
    font-size: var(--fz-20);
    height: 54px;
    padding: 10px 30px;
  }
}

.cntLoader {
  width: 252px !important;
  height: 54px !important;
  transform: rotate(5deg);
  margin-left: 20px;

  @media (max-width: $bp-m) {
    width: 200px !important;
    height: 43px !important;
    margin-left: 0;
    position: absolute !important;
    top: -40px;
    left: 20px;
  }
}

.title {
  white-space: nowrap;
}

.button {
  border-radius: 7px;
  height: 58px;
  width: 100%;
  max-width: 100%;
  font-size: var(--fz-18);
  line-height: 31px;
  font-weight: 500;
  text-align: center;
  color: var(--text-color-inverted);
  justify-content: center;
  align-items: center;

  @media(min-width: $bp-l) {
    max-width: 360px;
    width: 100%;
    display: flex;
  }
}

.flexContainer {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.card {
  position: relative;
  flex: 1;
  width: 100%;
  border-radius: 10px;
  background: var(--text-color-inverted);
  min-height: fit-content;
  max-height: fit-content;
  min-width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    height: 240px;
    border-radius: 10px;

    @media (max-width: 740px) {
      max-height: 240px;
    }
  }

  @media (min-width: 1090px) {
    max-width: 33%;
  }

  @media (max-width: 730px) {
    max-width: 100%;
  }
}

.vehicleCharacteristics {
  opacity: 0;
  position: absolute;
  background: hsla(0, 0%, 0%, 0.7);
  left: 0;
  right: 0;

  height: 240px;
  width: 100%;
  min-height: 240px;
  border-radius: 10px;

  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  align-content: flex-end;

  transition: opacity 0.3s;

  p {
    font-size: var(--fz-14);
    line-height: 24px;
  }
}

.cardVehicle {
  min-width: 490px;

  &:hover {
    .vehicleCharacteristics {
      opacity: 1;
    }
  }

  .cardContent {
    min-height: 220px;
    height: auto;

    @media (max-width: $bp-s) {
      min-height: auto;
    }
  }

  img {
    display: block;
    height: 240px;
    width: 100%;
    min-height: 240px;
  }

  @media (max-width: 1562px) {
    min-width: 30%;
  }

  @media (max-width: 1000px) {
    min-width: 40%;
  }

  @media (max-width: 648px) {
    min-width: 100%;
  }
}

.time {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  height: 35px;
  min-width: 90px;
  background: var(--light-green);
  color: var(--green);
  font-size: var(--fz-14);
  font-weight: 500;
  white-space: nowrap;
}

.cardContent {
  height: 220px;
}

.cardTitle {
  font-weight: 700;
  font-size: var(--fz-16);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 740px) {
    font-size: var(--fz-18);
  }
}

.cardDescription {
  margin: 8px 0;
  color: var(----light-text-color);
  font-size: var(--fz-14);
  line-height: 27px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;

  @media (min-width: 740px) {
    margin: 10px 0;
    font-size: var(--fz-16);
  }
}

.taskDetails {
  display: flex;
  align-items: center;
}

.taskDetailsVehicle {
  display: grid;
  grid-template-columns: 125px 1fr;
  align-items: flex-start;
  margin: 10px 0;
}

.detailTitle {
  color: var(--light-text-color);
  font-size: var(--fz-14);
  line-height: 24px;
}

.detail {
  color: var(--text-color);
  margin-left: 10px;
  font-size: var(--fz-14);
  line-height: 24px;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.costBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.cost {
  font-weight: 700;
  font-size: var(--fz-24);
  line-height: 30px;
  white-space: nowrap;

  @media (max-width: 1100px) {
    font-size: var(--fz-20);
  }
}

.link {
  font-size: var(--fz-16);
  line-height: 28px;
  white-space: nowrap;

  @media (max-width: 1300px) {
    font-size: var(--fz-12);
  }

  @media (max-width: $bp-m) {
    font-size: var(--fz-16);
  }
}

.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px auto 16px auto;
  width: 100%;
  max-width: calc(100vw - 32px);
  padding: 15px 42px 15px 42px;
  border-radius: 10px;

  @media (min-width: $bp-l) {
    margin: 60px auto 200px auto;
    max-width: 360px;
  }
}

.desktop {
  display: none;
  @media (min-width: $bp-l) {
    display: flex;
  }
}

.mobile {
  display: flex;
  @media (min-width: $bp-l) {
    display: none;
  }
}

.detailTitleVehicle {
  font-weight: 500;
  line-height: 24px;
}

.detailVehicle {
  color: var(--light-text-color);
  line-height: 24px;
}

.new, .newVehicle {
  animation: show 0.5s ease 0.5s;
  animation-fill-mode: forwards;
  opacity: 0.1;
}

@keyframes show {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

