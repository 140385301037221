@import '@/app/variables';

.button {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 70px;
  height: 70px;
  border-radius: 7px;
  z-index: 50;
  box-shadow: 5px 5px 35px 0 hsla(213, 21%, 14%, 0.05);
  opacity: 0.8;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width: $bp-s) {
    right: 10px;
    bottom: 10px;
    width: 40px;
    height: 40px;
  }

  &:hover {
    opacity: 1;
  }
}

.button.executor {
  border: 1px solid var(--primary-executor-color);
  background-color: var(--primary-executor-superlight-color);
}

.button.customer {
  border: 1px solid var(--primary-customer-color);
  background-color: var(--primary-customer-superlight-color);
}

.button.partner {
  border: 1px solid var(--primary-partner-color);
  background-color: var(--primary-partner-superlight-color);
}