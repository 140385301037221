@import '@/app/variables';

.wrapper {
  font-family: 'Gilroy', sans-serif;
  position: fixed;
  z-index: 50;
  padding: 22px;
  border-radius: 10px;
  bottom: 30px;
  left: 50%;
  width: 95%;
  max-width: 680px;
  transform: translateX(-50%);
  box-shadow: 5px 5px 35px 0 hsla(213, 21%, 14%, 0.05);
  background-color: var(--text-color-inverted);
  @media(max-width: $bp-m) {
    bottom: 10px;
  }
}

.text {
  color: var(--pale-text-color);
  font-size: var(--fz-14);
  line-height: 27px;
  @media(max-width: $bp-m) {
    font-size: var(--fz-12);
  }
}

.close {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 5px;
  right: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.0711 20.3361L16.7678 15.0328L22.0711 9.72946C22.3055 9.49504 22.4372 9.1771 22.4372 8.84558C22.4372 8.51406 22.3055 8.19611 22.0711 7.96169C21.8366 7.72727 21.5187 7.59558 21.1872 7.59558C20.8557 7.59558 20.5377 7.72727 20.3033 7.96169L15 13.265L9.6967 7.96169C9.46228 7.72727 9.14434 7.59558 8.81282 7.59558C8.48129 7.59558 8.16335 7.72727 7.92893 7.96169C7.69451 8.19611 7.56282 8.51406 7.56282 8.84558C7.56282 9.1771 7.69451 9.49504 7.92893 9.72946L13.2322 15.0328L7.92893 20.3361C7.69451 20.5705 7.56281 20.8884 7.56281 21.2199C7.56281 21.5515 7.69451 21.8694 7.92893 22.1038C8.16335 22.3383 8.48129 22.4699 8.81281 22.4699C9.14434 22.4699 9.46228 22.3383 9.6967 22.1038L15 16.8005L20.3033 22.1038C20.5377 22.3383 20.8557 22.4699 21.1872 22.4699C21.5187 22.4699 21.8366 22.3383 22.0711 22.1038C22.3055 21.8694 22.4372 21.5515 22.4372 21.2199C22.4372 20.8884 22.3055 20.5705 22.0711 20.3361Z' fill='%23006CEC'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  @media(max-width: $bp-m) {
    top: 15px;
    right: 15px;
  }
}
