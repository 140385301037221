@import "src/app/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $bp-l) {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  position: relative;
  width: 100%;
  height: 352px;
  cursor: pointer;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: $bp-s) {
    height: 468px;
  }
}

.title {
  color: var(--text-color-inverted);
  font-weight: 700;
  font-size: var(--fz-24);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  white-space: nowrap;
  transition: top 0.5s ease-in-out, opacity 0.5s ease-in-out;

  @media (min-width: $bp-s) {
    font-size: var(--fz-40);
  }

  &.focus {
    top: 70px;
    opacity: 1;

    @media (min-width: $bp-s) {
      top: 100px;
    }
  }
}


.focusBlock {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  width: 335px;
  transition: transform 0.5s ease-in-out, top 0.5s ease-in-out, opacity 0.5s ease-in-out;

  @media (min-width: $bp-s) {
    width: 450px;
  }

  &.focus {
    transform: translate(-50%, -90px);
    top: 55%;
    opacity: 1;
    height: 92px;


    @media (min-width: $bp-s) {
      transform: translate(-50%, -100px);
      height: 110px;
    }
  }
}

.focusBlockInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line {
  background: var(--text-color-inverted);
  width: 140px;
  height: 4px;
  border-radius: 2px;
}

.description {
  text-align: center;
  color: var(--text-color-inverted);
  font-size: var(--fz-14);
  font-weight: 500;
  margin-top: 20px;

  @media (min-width: $bp-s) {
    font-size: var(--fz-20);
  }
}

.tagsWrapper {
  position: absolute;
  left: 20px;
  bottom: -40px;
  display: flex;
  z-index: 1;
  width: 100%;
  overflow-x: auto;
  transition: bottom 0.5s ease-in-out, opacity 0.5s ease-in-out;

  @media (min-width: $bp-m) {
    left: -5px;
  }

  &.focusTags {
    bottom: 40px;
    opacity: 1;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}


.tag {
  padding: 10px 50px 10px 50px;
  background: hsla(0, 0%, 100%, 0.2);
  border-radius: 99px;
  height: 40px;
  margin-right: 20px;
  color: var(--text-color-inverted);
  font-size: 16px;
  white-space: nowrap;
}