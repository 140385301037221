@import '@/app/variables';

.textBlock {
  font-size: var(--fz-20);
  line-height: 34px;
  color: var(--text-color);

  &:not(:last-child) {
    margin-bottom: 32px;
    @media (max-width: $bp-m) {
      margin-bottom: 24px;
    }
  }

  @media (max-width: $bp-m) {
    font-size: var(--fz-16);
    line-height: 24px;
  }
}

.text {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
