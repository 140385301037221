@import '@/app/variables';

.wrapper {

}

.phone {
  color: var(--text-color-inverted);
  display: block;
  font-weight: 500;
  font-size: var(--fz-40);
  line-height: 68px;
  margin-bottom: 10px;

  @media(max-width: $bp-m) {
    font-size: var(--fz-24);
    line-height: 40px;
  }
}

.email {
  color: var(--text-color-inverted);
  display:block;
  font-weight: 400;
  font-size: var(--fz-20);
  line-height: 34px;

  @media(max-width: $bp-m) {
    font-size: var(--fz-16);
    line-height: 27px;
    text-align: center;
  }
}